@import "config";

//ヒーローヘッダー
main {
    width: 100%;
    margin: 0 0 100px 0;
    display: block;
    position: relative;
    z-index: 1;
    
    @include breakpoint(mobile) {
    margin: 0 0 60px 0;
    }
    
    #hero {
    display: -ms-grid;display: grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    grid-gap: 10px;
    position: relative;
    
    @include breakpoint(tablet) {
    grid-template-columns: 1fr 1fr;
    }
    
    @include breakpoint(mobile) {
    display: block;
    }
    
    h1 {
    font-family: 'Oswald-DemiBold',sans-serif;
    @include font-size(120,120);
    @include thema-color();
    letter-spacing: -0.04em;
    padding: 24px;
    position: absolute;
    left: 0;
    top: 35%;
    background-color: rgba(255,255,255,1);
    z-index: 60;
    
    @include breakpoint(tablet) {
    width: 50%;
    margin: 0 25%;
    @include font-size(64,64);
    text-align: center;
    padding: 20px;
    top: 42%;
    background-color: rgba(255,255,255,0.7);
    }
    
    @include breakpoint(mobile) {
    width: 65%;
    margin: 0 15%;
    @include font-size(40,40);
    padding: 10px;
    }
    }
    
    p {
    font-family: 'NotoMedium';
    @include font-size(18,30);
    @include thema-color();
    letter-spacing: 0.06em;
    padding: 16px 30px;
    position: absolute;
    left: 33%;
    bottom: 5%;
    background-color: rgba(255,255,255,1);
    z-index: 60;
    
    span {
    display: block;
    }
    
    @include breakpoint(tablet) {
    display: none;
    }
    
    @include breakpoint(mobile) {
    display: none;
    }
    }
    
    #grid_01 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    background-color: #fafafa;
    
    @include breakpoint(mobile) {
    display: none;
    }
    }
    
    #grid_02 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    background-color: #fafafa;
    }
    
    #grid_03 {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    background-color: #fafafa;
    
    @include breakpoint(tablet) {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    }
    
    @include breakpoint(mobile) {
    display: none;
    }
    }
    
    #grid_04 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    background-color: #fafafa;
    
    @include breakpoint(tablet) {
    display: none;
    }
    }
}
}


//共通
.fade {
opacity: 0;
transition: 1.5s;
}

.fade.animated {
opacity: 1;
}


//アウトライン
#outline {
    padding: 0 10px;
    margin: 0 0 60px 0;

    h2 {
    text-align: center;
    margin: 0 0 30px 0;
    
    @include breakpoint(mobile) {
    margin: 0 0 15px 0;
    }
    
    p {
    font-family: 'GenEiKoburiMinT-R';
    @include font-size(37);
    @include thema-color();
    letter-spacing: 0.03em;
    display: inline;
    
    @include breakpoint(mobile) {
    @include font-size(22);
    display: block;
    text-align: left;
    }
    
    .tip_1,
    .tip_2,
    .tip_3,
    .tip_4,
    .tip_5,
    .tip_6,
    .tip_7,
    .tip_8,
    .tip_9 {
    display: inline;
    }
    
    .tip_1 {//々
    margin: 0 -0.2em 0 -0.2em;
    }
    
    .tip_2 {//暮
    margin: 0 -0.15em 0 -0.05em;
    }
    
    .tip_3 {//し
    margin: 0 -0.15em 0 -0.3em;
    }
    
    .tip_4 {//便
    margin: 0 0 0 -0.1em;
    }
    
    .tip_5 {//に
    margin: 0 0 0 -0.1em;
    }
    
    .tip_6 {//そ
    margin: 0 -0.2em 0 -0.5em;
    
    @include breakpoint(mobile) {
    margin: 0 -0.2em 0 0;
    }
    }
    
    .tip_7 {//て
    margin: 0 -0.05em 0 -0.15em;
    }
    
    .tip_8 {//地
    margin: 0 -0.15em 0 0.02em;
    }
    
    .tip_9 {//く
    margin: 0 -0.1em 0 -0.3em;
    }
    
    small {
    font-size: 0.9em;
    }
    }
    }

    p {
    font-family: 'GenEiKoburiMinT-R';
    @include font-size(23,40);
    @include thema-color();
    text-align: center;
    
    @include breakpoint(mobile) {
    @include font-size(16,24);
    text-align: justify;
    }
    
    span {
    display: block;
    
    @include breakpoint(mobile) {
    display: inline;
    }
    }
    
    small {
    font-size: 0.95em;
    }
    }
}


//概要
article {
    padding: 60px 0 0 0;
    margin: 0 0 60px 0;
    border-top: 1px solid #f0f0f0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
        
    @include breakpoint(mobile) {
    display: block;
    }
    
    section {
    width: 25%;
    
    @include breakpoint(mobile) {
    width: 100%;
    margin: 0 0 50px 0;
    }
    
    div {
    img {
    width: 100%;
    margin: 0 0 20px 0;
    border-radius: 3px;
    }
    }
    
    h2 {
    font-family: 'GenSenMaruGothicJP-Medium';
    @include font-size(20);
    @include thema-color();
    }
    
    p {
    font-family: 'NotoRegular';
    line-height: 1.6;
    }
    }
    
    section:nth-child(2) {
    padding: 0 6.25%;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    box-sizing: content-box;
    
    @include breakpoint(mobile) {
    padding: 0 0 0 0;
    border-right: none;
    border-left: none;
    box-sizing: border-box;
    }
    }
    
    section:last-child {
    @include breakpoint(tablet) {
    margin: 0 0 0 0;
    }
    }
}

//新着情報
#news {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 30px 20px;
    border-radius: 5px;
    background-color: #fafafa;
    
    @include breakpoint(mobile) {
    display: block;
    padding: 15px 10px;
    }
    
    h2 {
    font-family: 'GenSenMaruGothicJP-Medium';
    @include font-size(20);
    @include thema-color();
    
    @include breakpoint(mobile) {
    margin: 0 0 10px 0;
    }
    }
    
    #list {
    width: 78%;
    
    @include breakpoint(mobile) {
    width: 100%;
    }
    
    dl {
    width: 100%;
    padding: 0 0 15px 0;
    margin: 0 0 15px;
    display: table;
    border-bottom: 1px solid #f0f0f0;
    
    @include breakpoint(mobile) {
    display: block;
    }
    
    dt {
    width: 8em;
    font-family: 'Lato-Regular';
    font-size: 80%;
    display: table-cell;
    
    @include breakpoint(mobile) {
    display: block;
    }
    }
    
    dd {
    @include font-size(14,22);
    }
    }
    }
}